document.addEventListener("DOMContentLoaded", function (event) {
  addServiceMoreButtonClickEvent();
});

function addServiceMoreButtonClickEvent() {
  const moreButtons = document.querySelectorAll('[data-modal-button="services"]');
  moreButtons.forEach((moreButton) => {
    moreButton.addEventListener('click', updateModal);
  })
}

function updateModal() {
  const modalBody = document.querySelector('[data-modal-body="services"]');
  const serviceName = this.dataset.modalService;

  // TODO should be using fetch really.
  const url = location.protocol + '//' + location.host;
  const route = `/update_service_modal/${encodeURIComponent(serviceName)}`;
  const fullUrl = url.concat(route);
  const xhr = new XMLHttpRequest()
  xhr.open("GET", route)
  xhr.setRequestHeader("Content-Type", "application/javascript");
  xhr.send()
  xhr.onload = function () {
    modalBody.innerHTML = xhr.responseText;
  }
}
